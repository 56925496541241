@import './index'

.v-bottom-navigation
  display: flex
  max-width: 100%
  overflow: hidden
  position: fixed
  transition: $bottom-navigation-transition

  @include border($bottom-navigation-border...)
  @include rounded($bottom-navigation-border-radius)
  @include theme($bottom-navigation-theme...)

  &--is-active
    @include elevation($bottom-navigation-elevation)

  &--absolute
    position: absolute

.v-bottom-navigation__content
  display: flex
  flex: none
  justify-content: center
  transition: inherit
  width: 100%
