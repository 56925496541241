@import './_variables.scss'

.v-ripple
  &__container
    color: inherit
    border-radius: inherit
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    overflow: hidden
    z-index: 0
    pointer-events: none
    contain: strict

  &__animation
    color: inherit
    position: absolute
    top: 0
    left: 0
    border-radius: 50%
    background: currentColor
    opacity: 0
    pointer-events: none
    overflow: hidden
    will-change: transform, opacity

    &--enter
      transition: none

    &--in
      transition: $ripple-animation-transition-in

    &--out
      transition: $ripple-animation-transition-out
