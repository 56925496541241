// Defaults
$divider-border-color: $border-color-root !default;
$divider-border-style: $border-style-root !default;
$divider-border-width: thin 0 0 0 !default;
$divider-flex: 1 1 100% !default;
$divider-inset-margin: 72px !default;
$divider-inset-max-width: calc(100% - #{$divider-inset-margin}) !default;
$divider-margin: 8px !default;
$divider-vertical-border-width: 0 thin 0 0 !default;
$divider-vertical-inset-margin-bottom: $divider-margin !default;
$divider-vertical-inset-margin-top: $divider-margin !default;
$divider-vertical-inset-max-height: calc(100% - #{$divider-margin * 2}) !default;
$divider-vertical-margin-left: -1px !default;

$divider-border: (
  $divider-border-color,
  $divider-border-style,
  $divider-border-width
) !default;
