// Imports
@import './index'

.v-divider
  display: block
  flex: $divider-flex
  height: 0px
  max-height: 0px
  transition: inherit

  @include border($divider-border...)

  &--vertical
    align-self: stretch
    border-width: $divider-vertical-border-width
    display: inline-flex
    height: inherit
    margin-left: $divider-vertical-margin-left
    max-height: 100%
    max-width: 0px
    vertical-align: text-bottom
    width: 0px

  &--inset
    &:not(.v-divider--vertical)
      max-width: $divider-inset-max-width
      margin-inline-start: $divider-inset-margin

    &.v-divider--vertical
      margin-bottom: $divider-vertical-inset-margin-bottom
      margin-top: $divider-vertical-inset-margin-top
      max-height: $divider-vertical-inset-max-height
