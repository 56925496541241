@import './index'

@mixin firefox()
  @-moz-document url-prefix()
    @content

.v-main
  display: flex
  flex: 1 0 auto
  max-width: 100%
  transition: $main-transition

  &__wrap
    flex: 1 1 auto
    max-width: 100%
    position: relative

  +firefox()
    @media print
      display: block
