// Defaults
$navigation-drawer-background: rgb(var(--v-theme-surface)) !default;
$navigation-drawer-border-color: $border-color-root !default;
$navigation-drawer-border-radius: map-get($rounded, '0') !default;
$navigation-drawer-border-style: $border-style-root !default;
$navigation-drawer-border-thin-width: thin !default;
$navigation-drawer-border-width: 0 !default;
$navigation-drawer-color: rgb(var(--v-theme-on-surface)) !default;
$navigation-drawer-content-height: 100% !default;
$navigation-drawer-content-overflow-x: hidden !default;
$navigation-drawer-content-overflow-y: auto !default;
$navigation-drawer-elevation: 0 !default;
$navigation-drawer-height: 100% !default;
$navigation-drawer-img-height: inherit !default;
$navigation-drawer-img-object-fit: cover !default;
$navigation-drawer-img-width: inherit !default;
$navigation-drawer-overflow-scrolling: touch !default;
$navigation-drawer-rounded-border-radius: $border-radius-root !default;
$navigation-drawer-temporary-elevation: 16 !default;
$navigation-drawer-transition-duration: 0.2s !default;
$navigation-drawer-transition-property: box-shadow, transform, visibility, width !default;
$navigation-drawer-transition-timing-function: $standard-easing !default;
$navigation-drawer-will-change: transform !default;

$navigation-drawer-border: (
  $navigation-drawer-border-color,
  $navigation-drawer-border-style,
  $navigation-drawer-border-width,
  $navigation-drawer-border-thin-width
) !default;

$navigation-drawer-theme: (
  $navigation-drawer-background,
  $navigation-drawer-color
) !default;
