// Defaults
$banner-avatar-after-opacity: var(--v-border-opacity) !default;
$banner-avatar-after-opacity: var(--v-border-opacity) !default;
$banner-avatar-border-radius: map-get($rounded, 'circle') !default;
$banner-avatar-margin-end: 24px !default;
$banner-avatar-margin-start: 0 !default;
$banner-background: rgb(var(--v-theme-background)) !default;
$banner-color: rgb(var(--v-theme-on-surface)) !default;
$banner-border-color: $border-color-root !default;
$banner-border-radius: map-get($rounded, 0) !default;
$banner-border-style: $border-style-root !default;
$banner-border-thin-width: thin !default;
$banner-border-width: 0 0 thin 0 !default;
$banner-content-padding-end: 90px !default;
$banner-density: ('default': 0, 'comfortable': -1, 'compact': -2) !default;
$banner-elevation: 0 !default;
$banner-font-size: map-deep-get($typography, 'body-2', 'size') !default;
$banner-padding-bottom: 8px !default;
$banner-padding-end: 8px !default;
$banner-padding-start: 24px !default;
$banner-padding-top: 10px !default;
$banner-positions: absolute fixed sticky !default;
$banner-rounded-border-radius: $border-radius-root !default;
$banner-sticky-top: 0 !default;
$banner-text-color: rgb(var(--v-theme-on-surface)) !default;
$banner-text-line-height: map-deep-get($typography, 'subtitle-2', 'line-height') !default;
$banner-two-line-content-margin-bottom: 4px !default;
$banner-two-line-padding-top: 24px !default;
$banner-three-line-content-margin-bottom: 8px !default;
$banner-three-line-margin-bottom: 24px !default;
$banner-three-line-padding-top: 24px !default;
$banner-width: 100% !default;

// Mobile
$banner-mobile-avatar-margin-end: 16px !default;
$banner-mobile-content-padding-end: 8px !default;
$banner-mobile-padding-end: 8px !default;
$banner-mobile-padding-start: 16px !default;

$banner-border: (
  $banner-border-color,
  $banner-border-style,
  $banner-border-width,
  $banner-border-thin-width
) !default;

$banner-theme: (
  $banner-background,
  $banner-color
) !default;
