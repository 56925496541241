// Imports
@import './index'

.v-kbd
  background: rgb(var(--v-kbd-background-color))
  border-radius: $kbd-border-radius
  color: rgb(var(--v-kbd-color))
  font-size: $kbd-font-size
  font-weight: $kbd-font-weight
  padding: $kbd-padding

  @include elevation($kbd-elevation)
