// Defaults
$chip-append-margin-inline-end: 0 !default;
$chip-append-margin-inline-start: 8px !default;
$chip-background: rgba(var(--v-theme-on-surface), var(--v-activated-opacity)) !default;
$chip-border-color: $border-color-root !default;
$chip-border-radius: map-get($rounded, "pill") !default;
$chip-border-style: $border-style-root !default;
$chip-border-thin-width: thin !default;
$chip-border-width: 0 !default;
$chip-close-margin-inline-end: -4px !default;
$chip-close-margin-inline-start: 8px !default;
$chip-close-size: 18px !default;
$chip-color: currentColor !default;
$chip-density: ("default": 0, "comfortable": -2, "compact": -3) !default;
$chip-disabled-opacity: 0.3 !default;
$chip-elevation: 0 !default;
$chip-font-size: map-deep-get($typography, "button", "size") !default;
$chip-height: 32px !default;
$chip-icon-density: ("default": 3, "comfortable": 0, "compact": -2) !default;
$chip-icon-font-size: 24px !default;
$chip-icon-size: 48px !default;
$chip-label-border-radius: 8px !default;
$chip-link-focus-opacity: 0.3 !default;
$chip-padding-ratio: 2 + 2/3 !default;
$chip-plain-opacity: 0.26 !default;
$chip-prepend-margin-inline-end: 8px !default;
$chip-prepend-margin-inline-start: -12px !default;
$chip-rounded-border-radius: $border-radius-root !default;

$chip-sizes: (
  "height": $chip-height,
  "font-size": $chip-font-size,
) !default;

$chip-icon-sizes: (
  "font-size": $chip-icon-font-size,
  "size": $chip-icon-size,
) !default;

$chip-border: (
  $chip-border-color,
  $chip-border-style,
  $chip-border-width
) !default;

$chip-variants: (
  $chip-background,
  $chip-color,
  $chip-elevation,
  $chip-plain-opacity,
  'v-chip'
) !default;
