// Imports
@import ./index

.v-alert
  padding: $alert-padding
  position: relative

  @include elevation($alert-elevation)
  @include position($alert-positions)
  @include rounded($alert-border-radius)
  @include theme($alert-theme...)
  @include variant($alert-variants...)

  // has specificity issues without this selector
  &.v-alert--border
    --v-border-opacity: #{$alert-border-opacity}

  &--border
    padding-inline-start: $alert-border-padding-inline-start

  &:not(.v-alert--prominent)
    .v-avatar
      --v-avatar-height: #{$alert-avatar-height}

  &--variant-plain
    transition: $alert-plain-transition

  @at-root
    @include density('v-alert', $alert-density) using ($modifier)
      padding-top: $alert-padding + $modifier
      padding-bottom: $alert-padding + $modifier

      &.v-alert--prominent
        .v-alert__avatar
          margin-inline-start: $alert-prominent-avatar-margin-inline-start + $modifier / 2
          margin-inline-end: $alert-prominent-avatar-margin-inline-end + $modifier / 2

          .v-icon
            font-size: $alert-prominent-icon-font-size + ($modifier * $alert-icon-font-size-multiplier)

.v-alert__avatar
  align-self: flex-start
  display: flex
  position: relative
  margin-inline-start: $alert-avatar-margin-inline-start
  margin-inline-end: $alert-avatar-margin-inline-end

  .v-alert--prominent &
    align-self: center
    flex: 1 0 auto
    max-width: $alert-prominent-icon-size

  .v-alert--tip &
    margin-inline-start: $alert-tip-margin-inline-start
    max-height: $alert-tip-max-height
    max-width: $alert-tip-max-width

    &::before
      content: ''
      top: 4px
      left: 4px
      position: absolute
      width: 16px
      height: 16px
      background: white
      border-radius: inherit

    .v-avatar
      height: auto
      max-width: inherit
      max-height: inherit

.v-alert__border
  border-radius: inherit
  bottom: 0
  left: 0
  opacity: var(--v-border-opacity)
  position: absolute
  right: 0
  top: 0
  width: 100%

  @include border($alert-border...)

  .v-alert--border-start &
    border-inline-start-width: $alert-border-thin-width
    border-inline-end-width: 0

  .v-alert--border-top &
    border-top-width: $alert-border-thin-width
    border-bottom-width: 0

  .v-alert--border-bottom &
    border-bottom-width: $alert-border-thin-width
    border-top-width: 0

  .v-alert--border-end &
    border-inline-end-width: $alert-border-thin-width
    border-inline-start-width: 0

  .v-alert--border.v-alert--tip &
    --v-border-opacity: 1
    border-inline-start-width: $alert-tip-border-inline-start-width

.v-alert__close
  align-self: flex-start
  display: flex
  flex: 0 1 auto
  margin-inline-start: $alert-close-margin-inline-start
  padding-inline-start: $alert-close-padding-inline-start

  .v-btn
    height: inherit
    width: inherit

  .v-icon
    font-size: $alert-close-icon-font-size

.v-alert__content
  display: flex

  .v-alert--prominent &
    align-items: center

.v-alert-text
  padding: $alert-text-padding

  .v-alert--border-start &
    padding-inline-end: #{$alert-text-padding + $alert-border-thin-width}

  .v-alert--border-end &
    padding-inline-start: #{$alert-text-padding + $alert-border-thin-width}

.v-alert__text
  display: flex
  flex: 1 1
