// Imports
@import './index'

.v-navigation-drawer
  -webkit-overflow-scrolling: $navigation-drawer-overflow-scrolling
  background: $navigation-drawer-background
  display: flex
  flex-direction: column
  height: $navigation-drawer-height
  max-width: 100%
  pointer-events: auto
  top: 0
  transition-duration: $navigation-drawer-transition-duration
  transition-property: $navigation-drawer-transition-property
  transition-timing-function: $navigation-drawer-transition-timing-function
  will-change: $navigation-drawer-will-change
  position: fixed

  @include border($navigation-drawer-border...)
  @include elevation($navigation-drawer-elevation)
  @include rounded($navigation-drawer-border-radius)
  @include theme($navigation-drawer-theme...)

  &--absolute
    position: absolute

  &--floating
    border-color: transparent

  &--rounded
    @include rounded($navigation-drawer-rounded-border-radius)

  &--start
    border-inline-end-width: $navigation-drawer-border-thin-width

    @include ltr()
      left: 0
      right: auto

    @include rtl()
      left: auto
      right: 0

  &--end
    border-inline-start-width: $navigation-drawer-border-thin-width

    @include ltr()
      left: auto
      right: 0

    @include rtl()
      left: 0
      right: auto

  &--temporary
    @include elevation($navigation-drawer-temporary-elevation)

.v-navigation-drawer__content
  flex: 0 1 auto
  height: $navigation-drawer-content-height
  max-width: 100%
  overflow-x: $navigation-drawer-content-overflow-x
  overflow-y: $navigation-drawer-content-overflow-y

.v-navigation-drawer__img
  height: 100%
  left: 0
  position: absolute
  top: 0
  width: 100%
  z-index: -1

  img
    height: $navigation-drawer-img-height
    object-fit: $navigation-drawer-img-object-fit
    width: $navigation-drawer-img-width
