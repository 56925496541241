@import './index'

.v-container
  +make-container
  +make-container-max-widths

  &--fluid
    max-width: 100%

// Row
//
// Rows contain and clear the floats of your columns.
.v-row
  +make-row

  & + .row
    margin-top: $grid-gutter / 2

    &--dense
      margin-top: $form-grid-gutter / 2

  &--dense
    margin: -$form-grid-gutter / 2

    > .v-col,
    > [class*="v-col-"]
      padding: $form-grid-gutter / 2

  // Remove the negative margin from default .v-row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  &.v-row--no-gutters
    margin: 0
    > .v-col,
    > [class*="v-col-"]
      padding: 0

// Columns
//
// Common styles for small and large grid columns
+make-grid-columns
