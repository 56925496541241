// Imports
@import './index'

// Theme
.v-timeline
  .v-timeline-divider__dot
    background: $timeline-dot-divider-background

// Elements
.v-timeline
  display: grid
  grid-auto-flow: dense
  position: relative

  @include horizontal(true)
    grid-template-rows: $timeline-item-grid-template
    width: 100%

    .v-timeline-item__body, .v-timeline-item__opposite
      padding-inline-start: calc((var(--v-timeline-dot-size) / 2) - 5px)

    .v-timeline-item:nth-child(2n)
      .v-timeline-item__body
        grid-row: 3
        padding-top: $timeline-item-padding

      .v-timeline-item__opposite
        grid-row: 1
        padding-bottom: $timeline-item-padding

    .v-timeline-item:nth-child(2n+1)
      .v-timeline-item__body
        grid-row: 1
        padding-bottom: $timeline-item-padding

      .v-timeline-item__opposite
        grid-row: 3
        padding-top: $timeline-item-padding

  @include vertical(true)
    grid-template-columns: $timeline-item-grid-template
    height: 100%

    .v-timeline-item__body, .v-timeline-item__opposite
      // TODO: replace with css var?
      padding-top: calc((var(--v-timeline-dot-size) - 1.5em) / 2)

    .v-timeline-item:nth-child(2n)
      .v-timeline-item__body
        grid-column: 1
        text-align: end
        padding-inline-end: $timeline-item-padding

      .v-timeline-item__opposite
        grid-column: 3
        padding-inline-start: $timeline-item-padding

    .v-timeline-item:nth-child(2n+1)
      .v-timeline-item__body
        grid-column: 3
        padding-inline-start: $timeline-item-padding

      .v-timeline-item__opposite
        grid-column: 1
        text-align: end
        padding-inline-end: $timeline-item-padding

.v-timeline-item
  display: contents

.v-timeline-divider__line
  background: $timeline-divider-line-background
  position: absolute

  @include horizontal
    height: $timeline-divider-line-thickness
    width: calc(100% - var(--v-timeline-line-inset, 0px))

  @include vertical
    height: 100%
    width: $timeline-divider-line-thickness

.v-timeline--inset-line
  .v-timeline-divider__line
    @include vertical(true)
      height: calc(100% - var(--v-timeline-dot-size) - #{$timeline-inset-divider-line})
      bottom: calc(#{$timeline-inset-divider-line / 2})

    @include horizontal(true)
      width: calc(100% - var(--v-timeline-dot-size) - #{$timeline-inset-divider-line})
      inset-inline-end: calc(#{$timeline-inset-divider-line} / 2)

.v-timeline:not(.v-timeline--inset-line)
  .v-timeline-item:first-child
    .v-timeline-divider__line
      @include vertical(true)
        bottom: 0
        height: calc(100% - (var(--v-timeline-dot-size) / 2))

      @include horizontal(true)
        width: calc(100% - (var(--v-timeline-dot-size) / 2))
        inset-inline-end: 0

.v-timeline-divider
  position: relative
  display: flex
  align-items: center

  @include horizontal
    flex-direction: row
    grid-row: 2

  @include vertical
    flex-direction: column
    grid-column: 2

.v-timeline-divider__dot
  z-index: 1
  flex-shrink: 0
  border-radius: $timeline-dot-border-radius
  display: flex
  justify-content: center
  align-items: center

  @include elevation($timeline-divider-dot-elevation)

  @each $name, $multiplier in $size-scales
    $size: $timeline-dot-size + (8 * $multiplier)

    &--size-#{$name}
      height: $size
      width: $size

      .v-timeline-divider__inner-dot
        height: calc(100% - #{map-get($timeline-dot-border-sizes, $name)})
        width: calc(100% - #{map-get($timeline-dot-border-sizes, $name)})

.v-timeline-divider__inner-dot
  align-items: center
  border-radius: $timeline-dot-border-radius
  display: flex
  justify-content: center

/** Modifiers **/

// Density
.v-timeline--density-comfortable
  @include horizontal(true)
    height: 100%

    &.v-timeline--side-end
      grid-template-rows: $timeline-density-comfortable-grid-template-end

    &.v-timeline--side-start
      grid-template-rows: $timeline-density-comfortable-grid-template-start

  @include vertical(true)
    width: 100%

    &.v-timeline--side-end
      grid-template-columns: $timeline-density-comfortable-grid-template-end

    &.v-timeline--side-start
      grid-template-columns: $timeline-density-comfortable-grid-template-start

.v-timeline--density-compact
  @include horizontal(true)
    &.v-timeline--side-end
      grid-template-rows: $timeline-density-compact-grid-template-end

    &.v-timeline--side-start
      grid-template-rows: $timeline-density-compact-grid-template-start

    .v-timeline-item__body
      grid-row: 1

  @include vertical(true)
    &.v-timeline--side-end
      grid-template-columns: $timeline-density-compact-grid-template-end

    &.v-timeline--side-start
      grid-template-columns: $timeline-density-compact-grid-template-start

    .v-timeline-item__body
      grid-column: 3

// Side
.v-timeline.v-timeline--side-end
  .v-timeline-item
    @include horizontal(true)
      .v-timeline-item__body
        grid-row: 3
        padding-bottom: initial
        padding-top: $timeline-item-padding

      .v-timeline-item__opposite
        grid-row: 1
        padding-bottom: $timeline-item-padding
        padding-top: initial

    @include vertical(true)
      .v-timeline-item__body
        grid-column: 3
        text-align: initial
        padding-inline-start: $timeline-item-padding

      .v-timeline-item__opposite
        grid-column: 1
        padding-inline-end: $timeline-item-padding

.v-timeline.v-timeline--side-start
  .v-timeline-item
    @include horizontal(true)
      .v-timeline-item__body
        grid-row: 1
        padding-bottom: $timeline-item-padding
        padding-top: initial

      .v-timeline-item__opposite
        grid-row: 3
        padding-bottom: initial
        padding-top: $timeline-item-padding

    @include vertical(true)
      .v-timeline-item__body
        grid-column: 1
        text-align: end
        padding-inline-end: $timeline-item-padding

      .v-timeline-item__opposite
        grid-column: 3
        padding-inline-start: $timeline-item-padding

// Fill dot
.v-timeline-divider--fill-dot
  .v-timeline-divider__inner-dot
    height: inherit
    width: inherit

.v-timeline--truncate-line-end
  .v-timeline-item:last-child
    .v-timeline-divider__line
      display: none
