// Imports
@import './index'

.v-card
  color: $card-color
  overflow: hidden
  position: relative
  padding: $card-padding
  text-decoration: none
  transition-duration: $card-transition-duration
  transition-property: $card-transition-property
  transition-timing-function: $card-transition-timing-function

  @include border($card-border...)
  @include position($card-positions)
  @include rounded($card-border-radius)
  @include states('.v-card__overlay')
  @include variant($card-variants...)

  &--disabled
    pointer-events: none
    user-select: none

    > [class*='v-card-']
      opacity: $card-disabled-opacity

  &--flat
    box-shadow: none

  &--hover
    cursor: pointer

    &::before,
    &::after
      border-radius: inherit
      bottom: 0
      content: ''
      display: block
      left: 0
      pointer-events: none
      position: absolute
      right: 0
      top: 0
      transition: inherit

    &::before
      opacity: 1
      z-index: -1

      @include elevation($card-elevation)

    &::after
      z-index: 1
      opacity: 0

      @include elevation($card-hover-elevation)

    &:hover::after
      opacity: 1

    &:hover::before
      opacity: 0

    @include elevation(0)

  &--link
    cursor: pointer

  &--tile
    @include rounded($card-tile-border-radius)

.v-card-actions
  align-items: center
  display: flex
  flex: 1 1 auto
  min-height: $card-actions-min-height
  padding: $card-actions-padding

.v-card-avatar
  align-self: $card-avatar-align-self
  padding: $card-avatar-padding

  &:last-child
    .v-card-header &
      padding-inline-end: $card-avatar-header-padding

  &:first-child
    .v-card-header &
      padding-inline-start: $card-avatar-header-padding

.v-card-header-text
  flex: 1 1 auto

.v-card-img
  border-radius: inherit
  display: flex
  height: 100%
  left: 0
  overflow: hidden
  position: absolute
  top: 0
  width: 100%

.v-card-header
  align-items: $card-item-align-items
  display: flex
  padding: $card-item-padding

.v-card-media
  overflow: hidden

.v-card-subtitle
  align-items: center
  display: flex
  font-size: $card-subtitle-font-size
  font-weight: $card-subtitle-font-weight
  letter-spacing: $card-subtitle-letter-spacing
  opacity: $card-subtitle-opacity
  padding: $card-subtitle-padding
  text-transform: $card-subtitle-text-transform

  @include card-line-height-densities($card-subtitle-densities)

  .v-card-header &
    padding: $card-subtitle-header-padding

.v-card-text
  font-size: $card-text-font-size
  font-weight: $card-text-font-weight
  letter-spacing: $card-text-letter-spacing
  opacity: $card-text-opacity
  padding: $card-text-padding
  text-transform: $card-text-text-transform
  transition: inherit
  transition-property: color, opacity

  @include card-line-height-densities($card-text-densities)

.v-card-title
  align-items: center
  display: flex
  font-size: $card-title-font-size
  font-weight: $card-title-font-weight
  hyphens: $card-title-hyphens
  letter-spacing: $card-title-letter-spacing
  overflow-wrap: $card-title-overflow-wrap
  padding: $card-title-padding
  text-transform: $card-title-text-transform
  word-break: $card-title-word-break
  word-wrap: $card-title-word-wrap

  @include card-line-height-densities($card-title-densities)

  .v-card-header &
    padding: $card-title-header-padding

.v-card__overlay
  background-color: currentColor
  border-radius: inherit
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  pointer-events: none
  opacity: 0
  transition: opacity 0.2s ease-in-out

// VDialog
.v-card
  .v-dialog .v-overlay__content > &
    border-radius: inherit
    overflow: hidden

    @include elevation($card-dialog-elevation)
