@mixin transition-default() {
  &-enter-active {
    transition: 0.3s $standard-easing;
  }

  &-leave-active {
    transition: 0.3s $standard-easing;
  }

  &-move {
    transition: transform .5s $standard-easing;
  }
}

// Component specific transitions
.carousel-transition {
  &-enter-from {
    transform: translate(100%, 0);
  }

  &-leave-from, &-leave-to {
    position: absolute;
    top: 0;
    transform: translate(-100%, 0);
  }
}

.carousel-reverse-transition {
  &-enter-from {
    transform: translate(-100%, 0);
  }

  &-leave-from, &-leave-to {
    position: absolute;
    top: 0;
    transform: translate(100%, 0);
  }
}

.dialog-transition {
  &-enter-active {
    transition: 225ms $decelerated-easing
  }

  &-leave-active {
    transition: 125ms $accelerated-easing
  }

  &-enter-active,
  &-leave-active {
    transition-property: transform, opacity;
  }

  &-enter-from, &-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }

  &-enter-to, &-leave-from {
    opacity: 1;
  }
}

.dialog-bottom-transition {
  &-enter-from, &-leave-to {
    transform: translateY(100%);
  }
}

.dialog-top-transition {
  &-enter-from, &-leave-to {
    transform: translateY(-100%);
  }
}

.picker-transition,
.picker-reverse-transition {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-leave-from,
  &-leave-active,
  &-leave-to {
    position: absolute !important;
  }
}

.picker-transition {
  &-enter-from {
    transform: translate(0, 100%);
  }

  &-leave-to {
    transform: translate(0, -100%);
  }
}

.picker-reverse-transition {
  &-enter-from {
    transform: translate(0, -100%);
  }

  &-leave-to {
    transform: translate(0, 100%);
  }
}

.picker-title-transition {
  &-enter-to,
  &-leave-from {
    transform: translate(0, 0);
  }

  &-enter-from {
    transform: translate(-100%, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate(100%, 0);
  }

  &-leave-from,
  &-leave-to,
  &-leave-active {
    position: absolute !important;
  }
}

.tab-transition {
  &-enter-from {
    transform: translate(100%, 0);
  }

  &-leave-from, &-leave-active {
    position: absolute;
    top: 0;
  }

  &-leave-to {
    position: absolute;
    transform: translate(-100%, 0);
  }
}

.tab-reverse-transition {
  &-enter-from {
    transform: translate(-100%, 0);
  }

  &-leave-from, &-leave-to {
    top: 0;
    position: absolute;
    transform: translate(100%, 0);
  }
}

// Generic transitions
.expand-transition {
  @include transition-default();
}

.expand-x-transition {
  @include transition-default();
}

.scale-transition {
  @include transition-default();

  &-enter-from, &-leave-from, &-leave-to {
    opacity: 0;
    transform: scale(0);
  }
}

.scale-rotate-transition {
  @include transition-default();

  &-enter-from, &-leave, &-leave-to {
    opacity: 0;
    transform: scale(0) rotate(-45deg);
  }
}

.scale-rotate-reverse-transition {
  @include transition-default();

  &-enter-from, &-leave-from, &-leave-to {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
}

.message-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
    transform: translateY(-15px);
  }

  &-leave-from, &-leave-active {
    position: absolute;
  }
}

.slide-y-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
    transform: translateY(-15px);
  }
}

.slide-y-reverse-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
    transform: translateY(15px);
  }
}

.scroll-y-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
  }

  &-enter-from {
    transform: translateY(-15px);
  }

  &-leave-to {
    transform: translateY(15px);
  }
}

.scroll-y-reverse-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
  }

  &-enter-from {
    transform: translateY(15px);
  }

  &-leave-to {
    transform: translateY(-15px);
  }
}

.scroll-x-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
  }

  &-enter-from {
    transform: translateX(-15px);
  }

  &-leave-to {
    transform: translateX(15px);
  }
}

.scroll-x-reverse-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
  }

  &-enter-from {
    transform: translateX(15px);
  }

  &-leave-to {
    transform: translateX(-15px);
  }
}

.slide-x-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
    transform: translateX(-15px);
  }
}

.slide-x-reverse-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0;
    transform: translateX(15px);
  }
}

.fade-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    opacity: 0 !important;
  }
}

.fab-transition {
  @include transition-default();

  &-enter-from, &-leave-to {
    transform: scale(0) rotate(-45deg);
  }
}
