// Defaults
$list-background: rgba(var(--v-theme-surface)) !default;
$list-border-color: $border-color-root !default;
$list-border-radius: 0 !default;
$list-border-style: $border-style-root !default;
$list-border-thin-width: thin !default;
$list-border-width: 0 !default;
$list-color: rgba(var(--v-theme-on-surface)) !default;
$list-disabled-opacity: 0.6 !default;
$list-elevation: 0 !default;
$list-padding: 8px 0 !default;
$list-positions: absolute fixed !default;
$list-rounded-border-radius: map-get($rounded, null) !default;

$list-nav-subheader-font-size: .75rem !default;

$list-subheader-font-size: .875rem !default;
$list-subheader-font-weight: 500 !default;
$list-subheader-inset-margin: 56px !default;
$list-subheader-inset-padding-start: 72px !default;
$list-subheader-line-height: 1.375rem !default;
$list-subheader-min-height: 48px !default;
$list-subheader-padding-end: 16px !default;
$list-subheader-padding-start: 16px !default;
$list-subheader-padding-top: 0 !default;
$list-subheader-min-height-multiplier: 1 !default;
$list-subheader-text-opacity: var(--v-medium-emphasis-opacity) !default;
$list-subheader-transition: 0.2s min-height $standard-easing !default;

$list-item-border-color: $border-color-root !default;
$list-item-border-radius: $border-radius-root !default;
$list-item-border-style: $border-style-root !default;
$list-item-border-width: 0 !default;
$list-item-border-thin-width: thin !default;
$list-item-elevation: 0 !default;
$list-item-min-height: 48px !default;
$list-item-padding: 8px 16px !default;
$list-item-plain-opacity: .62 !default;
$list-item-rounded-border-radius: map-get($rounded, null) !default;
$list-item-transition: .2s $standard-easing !default;
$list-item-two-line-min-height: 64px !default;
$list-item-two-line-padding: 12px 16px !default;
$list-item-three-line-min-height: 88px !default;
$list-item-three-line-padding: 16px 16px !default;

$list-item-avatar-align-self: flex-start !default;
$list-item-avatar-margin-end: 16px !default;
$list-item-avatar-margin-start: 16px !default;
$list-item-avatar-size: 40px !default;
$list-item-avatar-size-multiplier: 1.5 !default;
$list-item-avatar-margin-y: 4px !default;

$list-item-media-margin-bottom: 0 !default;
$list-item-media-margin-end: 16px !default;
$list-item-media-margin-start: 16px !default;
$list-item-media-margin-top: 0 !default;
$list-item-media-two-line-margin-bottom: -4px !default;
$list-item-media-two-line-margin-top: -4px !default;
$list-item-media-three-line-margin-bottom: 0 !default;
$list-item-media-three-line-margin-top: 0 !default;

$list-item-nav-title-font-size: .8125rem !default;
$list-item-nav-title-font-weight: 500 !default;
$list-item-nav-title-letter-spacing: normal !default;
$list-item-nav-title-line-height: 1rem !default;
$list-item-nav-subtitle-font-size: .75rem !default;
$list-item-nav-subtitle-font-weight: map-deep-get($typography, 'body-2', 'weight') !default;
$list-item-nav-subtitle-letter-spacing: map-deep-get($typography, 'body-2', 'letter-spacing') !default;
$list-item-nav-subtitle-line-height: 1rem !default;

$list-item-subtitle-opacity: var(--v-medium-emphasis-opacity) !default;
$list-item-subtitle-font-size: map-deep-get($typography, 'body-2', 'size') !default;
$list-item-subtitle-font-weight: map-deep-get($typography, 'body-2', 'weight') !default;
$list-item-subtitle-letter-spacing: map-deep-get($typography, 'body-2', 'letter-spacing') !default;
$list-item-subtitle-line-height: 1rem !default;
$list-item-subtitle-padding: 0 !default;
$list-item-subtitle-text-transform: none !default;

$list-item-title-font-size: map-deep-get($typography, 'body-1', 'size') !default;
$list-item-title-font-weight: map-deep-get($typography, 'body-1', 'weight') !default;
$list-item-title-header-padding: 0 !default;
$list-item-title-hyphens: auto !default;
$list-item-title-letter-spacing: map-deep-get($typography, 'subtitle-1', 'letter-spacing') !default;
$list-item-title-line-height: map-deep-get($typography, 'body-1', 'line-height') !default;
$list-item-title-overflow-wrap: normal !default;
$list-item-title-padding: 0 !default;
$list-item-title-text-overflow: ellipsis;
$list-item-title-text-transform: none !default;
$list-item-title-word-break: normal !default;
$list-item-title-word-wrap: break-word !default;

$list-density: ('default': 0, 'comfortable': -1, 'compact': -2) !default;

$list-border: (
  $list-border-color,
  $list-border-style,
  $list-border-width,
  $list-border-thin-width
) !default;

$list-theme: (
  $list-background,
  $list-color
);

$list-item-border: (
  $list-item-border-color,
  $list-item-border-style,
  $list-item-border-width,
  $list-item-border-thin-width
) !default;

$list-item-title-typography: (
  $list-item-title-font-size,
  $list-item-title-font-weight,
  $list-item-title-letter-spacing,
  $list-item-title-line-height,
  $list-item-title-text-transform
);

$list-item-subtitle-typography: (
  $list-item-subtitle-font-size,
  $list-item-subtitle-font-weight,
  $list-item-subtitle-letter-spacing,
  $list-item-subtitle-line-height,
  $list-item-subtitle-text-transform
);

$list-item-nav-title-typography: (
  $list-item-nav-title-font-size,
  $list-item-nav-title-font-weight,
  $list-item-nav-title-letter-spacing,
  $list-item-nav-title-line-height,
  null
);

$list-item-nav-subtitle-typography: (
  $list-item-nav-subtitle-font-size,
  $list-item-nav-subtitle-font-weight,
  $list-item-nav-subtitle-letter-spacing,
  $list-item-nav-subtitle-line-height,
  null
);

$list-item-variants: (
  $list-background,
  $list-color,
  $list-item-elevation,
  $list-item-plain-opacity,
  'v-list-item'
);
