@import './index'

// Block
.v-overlay-container
  bottom: 0
  contain: strict
  left: 0
  pointer-events: none
  position: fixed
  right: 0
  top: 0

.v-overlay
  align-items: center
  border-radius: inherit
  display: flex
  height: 100%
  justify-content: center
  left: 0
  pointer-events: none
  position: fixed
  right: 0
  top: 0
  z-index: $overlay-z-index
  padding-inline-end: $overlay-padding-x

// Element
.v-overlay__content
  outline: none
  position: relative

.v-overlay__scrim
  background: $overlay-scrim-background
  border-radius: inherit
  bottom: 0
  height: 100%
  left: 0
  opacity: $overlay-opacity
  position: absolute
  right: 0
  top: 0
  width: 100%

// Modifier
.v-overlay--absolute
  position: absolute

.v-overlay--active
  pointer-events: auto
