// Imports
@import ./index

.v-btn
  align-items: center
  border-radius: $button-border-radius
  display: inline-flex
  font-weight: $button-font-weight
  justify-content: center
  letter-spacing: $button-text-letter-spacing
  line-height: $button-line-height
  outline: none
  overflow: visible
  position: relative
  text-decoration: none
  text-indent: $button-text-letter-spacing
  text-transform: $button-text-transform
  transition-property: box-shadow, transform, opacity, color
  transition-duration: 0.28s
  transition-timing-function: $standard-easing
  user-select: none
  vertical-align: $button-vertical-align
  flex-shrink: 0

  @at-root
    +button-sizes()
    +button-density('height', $button-density)

  @include border($button-border...)
  @include states('.v-btn__overlay', false)
  @include variant($button-variants...)

  &--flat
    box-shadow: none

  &--icon
    border-radius: $button-icon-border-radius
    min-width: 0
    padding: 0
    font-size: $button-icon-font-size

    @at-root &
      +button-density(('width', 'height'), $button-icon-density)

  &--elevated
    &:hover,
    &:focus
      +elevation(map-get($button-elevation, 'hover'))

    &:active
      +elevation(map-get($button-elevation, 'active'))

  &--block
    display: flex
    flex: 1 0 auto
    min-width: 100%

  &--disabled
    pointer-events: none

    &.v-btn--disabled
      color: rgba(var(--v-theme-on-surface), $button-disabled-opacity)
      opacity: 1

    &.v-btn--variant-contained
      background: rgb(var(--v-theme-surface))
      box-shadow: none

      .v-btn__overlay
        // This is multiplied by the text opacity,
        // so we need to divide it to get the desired value
        opacity: $button-disabled-overlay / $button-disabled-opacity

  &--stacked
    flex-direction: column
    line-height: $button-stacked-line-height

    @at-root
      @include button-sizes($button-stacked-sizes, true)
      @include button-density('height', $button-stacked-density)

.v-btn__overlay
  background-color: currentColor
  border-radius: inherit
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  pointer-events: none
  opacity: 0
  transition: opacity 0.2s ease-in-out

// VAppBar
.v-btn
  .v-app-bar &
    @include elevation($button-app-bar-elevation)

// VBottomNavigation
.v-btn
  .v-bottom-navigation &
    filter: $button-bottom-navigation-filter
    flex-direction: column
    font-size: $button-bottom-navigation-font-size
    height: auto
    max-width: $button-bottom-navigation-max-width
    min-width: $button-bottom-navigation-min-width
    opacity: $button-bottom-navigation-opacity
    text-transform: $button-bottom-navigation-text-transform
    transition-duration: $button-bottom-navigation-transition-duration
    transition-property: transform, opacity, filter
    width: auto

    @include elevation($button-bottom-navigation-elevation)
    @include rounded($button-bottom-navigation-border-radius)

  .v-bottom-navigation--grow &
    width: 100%

  .v-bottom-navigation--horizontal &
    flex-direction: row

  .v-bottom-navigation--shift &
    > .v-icon
      transform: $button-bottom-navigation-shift-icon-transform
      transition: inherit

    > *:not([class*='v-'])
      opacity: $button-bottom-navigation-shift-opacity
      position: absolute
      top: $button-bottom-navigation-shift-top
      transform: $button-bottom-navigation-shift-transform
      transition: inherit

  &--active
    .v-bottom-navigation &
      @include active-states('.v-btn__overlay')

      filter: $button-bottom-navigation-active-filter
      opacity: $button-bottom-navigation-active-opacity

    .v-bottom-navigation--shift &
      > .v-icon
        transform: $button-bottom-navigation-shift-active-icon-transform

      > *:not([class*='v-'])
        opacity: $button-bottom-navigation-active-opacity
        transform: $button-bottom-navigation-shift-active-transform

// VCard
.v-btn
  .v-card-actions &
    padding: $button-card-actions-padding

    @include elevation($button-card-actions-elevation)

  ~ .v-btn
    .v-card-actions &
      margin-inline-start: $button-card-actions-margin

// VBanner
.v-btn
  .v-banner-actions &
    padding: $button-banner-actions-padding

    @include elevation($button-banner-actions-elevation)

// VPagination
.v-btn
  .v-pagination &
    @include elevation($button-pagination-elevation)
    @include rounded($button-pagination-border-radius)
    @include button-density('height' 'width', $button-pagination-density)

  &--elevated
    .v-pagination__list &
      @include elevation($button-pagination-elevation)

  &__overlay
    .v-pagination__item--is-active &
      opacity: $button-pagination-active-overlay-opacity
