// Imports
@import './index'

.v-footer
  align-items: center
  display: flex
  flex: $footer-flex
  padding: $footer-padding
  position: relative

  // missing from variables @include border($footer-query)
  +elevation($footer-elevation)
  +position($footer-positions)
  +rounded($footer-border-radius)
  @include theme($footer-theme...)

  &--rounded
    +rounded($footer-rounded-border-radius)
