@import '../../styles/styles.sass';

// Defaults
$badge-border-color: rgb(var(--v-theme-background)) !default;
$badge-border-radius: 10px !default;
$badge-border-style: solid !default;
$badge-border-transform: scale(1.20) !default;
$badge-border-width: 2px !default;
$badge-background: rgb(var(--v-theme-surface)) !default;
$badge-color: rgb(var(--v-theme-on-surface)) !default;
$badge-content-padding: 0 2px !default;
$badge-dot-border-radius: 4.5px;
$badge-dot-border-width: 1.5px !default;
$badge-dot-height: 9px !default;
$badge-dot-width: 9px !default;
$badge-font-family: $body-font-family !default;
$badge-font-size: .75rem !default;
$badge-font-weight: 500 !default;
$badge-height: 1.25rem !default;
$badge-icon-margin: 0 -2px !default;
$badge-icon-padding: 4px 6px !default;
$badge-inline-vertical-align: middle !default;
$badge-line-height: 1 !default;
$badge-min-width: 20px !default;
$badge-padding: 4px !default;
$badge-transition: .225s $standard-easing !default;
$badge-wrapper-margin: 0 4px !default;

$badge-theme: (
  $badge-background,
  $badge-color
) !default;
