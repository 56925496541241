$app-bar-background: rgb(var(--v-theme-surface)) !default;
$app-bar-border-color: $border-color-root !default;
$app-bar-border-radius: map-get($rounded, '0') !default;
$app-bar-border-style: $border-style-root !default;
$app-bar-border-thin-width: 0 0 thin !default;
$app-bar-border-width: 0 !default;
$app-bar-collapsed-border-radius: 24px !default;
$app-bar-collapsed-max-width: 112px !default;
$app-bar-color: rgb(var(--v-theme-on-surface)) !default;
$app-bar-density-comfortable-padding: 4px !default;
$app-bar-density-compact-padding: 0 !default;
$app-bar-density-default-padding: 8px !default;
$app-bar-elevation: 4 !default;
$app-bar-flat-elevation: 0 !default;
$app-bar-image-height: inherit !default;
$app-bar-image-object-fit: cover !default;
$app-bar-image-width: inherit !default;
$app-bar-padding-end: 4px !default;
$app-bar-padding-start: 4px !default;
$app-bar-prominent-height: 128px !default;
$app-bar-rounded-border-radius: $border-radius-root !default;
$app-bar-scrolled-title-padding-bottom: 9px !default;
$app-bar-shaped-border-radius: map-get($rounded, 'xl') $app-bar-border-radius !default;
$app-bar-title-font-size: map-deep-get($typography, 'h5', 'size') !default;
$app-bar-title-padding: 6px 20px !default;
$app-bar-transition: .2s $standard-easing !default;

$app-bar-border: (
  $app-bar-border-color,
  $app-bar-border-style,
  $app-bar-border-width,
  $app-bar-border-thin-width
) !default;

$app-bar-theme: (
  $app-bar-background,
  $app-bar-color
) !default;
