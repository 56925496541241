@import './index'

.v-app-bar
  align-items: flex-start
  display: flex
  flex-direction: column
  justify-content: space-between
  max-width: 100%
  overflow: hidden
  padding-left: $app-bar-padding-start
  padding-right: calc(#{$app-bar-padding-end} + var(--v-scrollbar-offset))
  position: fixed
  transition: $app-bar-transition
  transition-property: height, transform

  // must be above border mixin for cascade
  &--is-active
    @include elevation($app-bar-elevation)

  @include border($app-bar-border...)
  @include rounded($app-bar-border-radius)
  @include theme($app-bar-theme...)

  &--absolute
    position: absolute

  &--border
    &.v-app-bar--bottom
      border-bottom-width: $app-bar-border-width
      border-top-width: $app-bar-border-thin-width

  &--collapsed
    max-width: $app-bar-collapsed-max-width
    overflow: hidden

    @include ltr()
      border-bottom-right-radius: $app-bar-collapsed-border-radius

    @include rtl()
      border-bottom-left-radius: $app-bar-collapsed-border-radius

  &--flat
    @include elevation($app-bar-flat-elevation)

  &--floating
    display: inline-flex

  &--rounded
    @include rounded($app-bar-rounded-border-radius)

.v-app-bar__content
  align-items: center
  display: flex
  flex: 0 0 auto
  transition: inherit
  width: 100%

  .v-app-bar--density-default &
    padding-bottom: $app-bar-density-default-padding
    padding-top: $app-bar-density-default-padding

  .v-app-bar--density-comfortable &
    padding-bottom: $app-bar-density-comfortable-padding
    padding-top: $app-bar-density-comfortable-padding

  .v-app-bar--density-compact &
    padding-bottom: $app-bar-density-compact-padding
    padding-top: $app-bar-density-compact-padding

.v-app-bar__extension
  align-items: center
  display: flex
  flex: 0 0 auto
  transition: inherit
  width: 100%

.v-app-bar__image
  height: 100%
  left: 0
  position: absolute
  top: 0
  width: 100%
  z-index: -1
  display: flex

.v-app-bar__append
  display: flex
  flex: 0 1 auto
  margin-inline-start: auto

.v-app-bar__prepend
  display: flex
  flex: 0 1 auto
