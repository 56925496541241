// Imports
@import './index'

.v-sheet
  @include border($sheet-border...)
  @include elevation($sheet-elevation)
  @include position($sheet-positions)
  @include rounded($sheet-border-radius)
  @include theme($sheet-theme...)

  &--rounded
    @include rounded($sheet-rounded-border-radius)

// VDialog
.v-sheet
  .v-dialog .v-overlay__content > &
    border-radius: inherit
    overflow: hidden

    @include elevation($sheet-dialog-elevation)
