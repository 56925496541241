@import './index'

.v-app-bar-title
  font-size: $app-bar-title-font-size
  padding: $app-bar-title-padding

.v-app-bar-title__placeholder
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
