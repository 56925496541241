@import './index'

.v-img
  --v-theme-overlay-multiplier: 3
  z-index: 0

.v-img__img,
.v-img__picture,
.v-img__placeholder,
.v-img__error
  z-index: -1
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.v-img__img
  &--preload
    filter: $img-preload-filter

  &--contain
    object-fit: contain

  &--cover
    object-fit: cover
