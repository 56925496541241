// Defaults
$button-app-bar-elevation: 0 !default;
$button-background: rgb(var(--v-theme-surface)) !default;
$button-color: rgb(var(--v-theme-on-surface)) !default;
$button-banner-actions-elevation: 0 !default;
$button-banner-actions-padding: 0 8px !default;
$button-bottom-navigation-elevation: 0 !default;
$button-bottom-navigation-border-radius: 0 !default;
$button-bottom-navigation-active-filter: grayscale(0) !default;
$button-bottom-navigation-active-opacity: 1 !default;
$button-bottom-navigation-filter: grayscale(1) !default;
$button-bottom-navigation-font-size: map-deep-get($typography, 'caption', 'size') !default;
$button-bottom-navigation-max-width: 168px !default;
$button-bottom-navigation-min-width: 80px !default;
$button-bottom-navigation-opacity: .64 !default;
$button-bottom-navigation-shift-active-icon-transform: translateY(-7px) !default;
$button-bottom-navigation-shift-active-transform: scale(1) translateY(5px) !default;
$button-bottom-navigation-shift-icon-transform: translateY(0) !default;
$button-bottom-navigation-shift-opacity: 0 !default;
$button-bottom-navigation-shift-top: 50% !default;
$button-bottom-navigation-shift-transform: scale(.8) translateY(10px) !default;
$button-bottom-navigation-text-transform: none !default;
$button-bottom-navigation-transition-duration: .2s !default;
$button-pagination-active-overlay-opacity: var(--v-border-opacity) !default;
$button-pagination-border-radius: $border-radius-root !default;
$button-pagination-elevation: 0 !default;
$button-border-color: $border-color-root !default;
$button-border-radius: $border-radius-root !default;
$button-border-style: $border-style-root !default;
$button-border-thin-width: thin !default;
$button-border-width: 0 !default;
$button-card-actions-elevation: 0 !default;
$button-card-actions-margin: .5rem !default;
$button-card-actions-padding: 0 8px !default;
$button-disabled-opacity: 0.26 !default;
$button-disabled-overlay: 0.12 !default;
$button-elevation: ('default': 2, 'hover': 4, 'active': 8) !default;
$button-font-size: map-deep-get($typography, 'button', 'size') !default;
$button-font-weight: map-deep-get($typography, 'button', 'weight') !default;
$button-height: 36px !default;
$button-stacked-height: 72px !default;
$button-icon-border-radius: map-get($rounded, 'circle') !default;
$button-icon-font-size: 1rem !default;
$button-icon-size: 48px !default;
$button-line-height: normal !default;
$button-stacked-line-height: 1.7 !default;
$button-plain-opacity: .62 !default;
$button-padding-ratio: 2.25 !default;
$button-stacked-padding-ratio: 4.5 !default;
$button-positions: absolute fixed !default;
$button-text-letter-spacing: map-deep-get($typography, 'button', 'letter-spacing') !default;
$button-text-transform: map-deep-get($typography, 'button', 'text-transform') !default;
$button-vertical-align: middle !default;
$button-width-ratio: 16/9 !default;
$button-stacked-width-ratio: 1 !default;

$button-density: ('default': 0, 'comfortable': -2, 'compact': -3) !default;
$button-stacked-density: ('default': 0, 'comfortable': -4, 'compact': -6) !default;
$button-icon-density: ('default': 3, 'comfortable': 0, 'compact': -2) !default;
$button-pagination-density: ('default': 0, 'comfortable': -1, 'compact': -2) !default;

$button-border: (
  $button-border-color,
  $button-border-style,
  $button-border-width,
  $button-border-thin-width
) !default;

$button-sizes: () !default;
$button-sizes: map-merge(
    (
      'height': $button-height,
      'font-size': $button-font-size,
      'width-ratio': $button-width-ratio,
      'padding-ratio': $button-padding-ratio
    ),
    $button-sizes
);

$button-stacked-sizes: () !default;
$button-stacked-sizes: map-merge(
    (
      'height': $button-stacked-height,
      'font-size': $button-font-size,
      'width-ratio': $button-stacked-width-ratio,
      'padding-ratio': $button-stacked-padding-ratio
    ),
    $button-stacked-sizes
);

$button-variants: (
  $button-background,
  $button-color,
  map-get($button-elevation, 'default'),
  $button-plain-opacity,
  'v-btn'
);
