@import ./index

.v-breadcrumbs
  line-height: $breadcrumbs-line-height
  padding: $breadcrumbs-padding

  &--rounded
    @include rounded($breadcrumbs-rounded-border-radius)

  @at-root
    @include density('v-breadcrumbs', $breadcrumbs-density) using ($modifier)
      padding-top: $breadcrumbs-padding + $modifier
      padding-bottom: $breadcrumbs-padding + $modifier


.v-breadcrumbs-item
  align-items: center
  color: inherit
  display: inline-flex
  padding: $breadcrumbs-item-padding
  text-decoration: none
  vertical-align: $breadcrumbs-vertical-align

  &--disabled
    opacity: $breadcrumbs-item-disabled-opacity
    pointer-events: none

  &--link:hover
    text-decoration: $breadcrumbs-item-link-text-decoration

  .v-icon
    font-size: $breadcrumbs-item-icon-font-size
    margin-inline-start: $breadcrumbs-item-icon-margin-inline-start
    margin-inline-end: $breadcrumbs-item-icon-margin-inline-end

.v-breadcrumbs-divider
  display: inline-block
  padding: $breadcrumbs-divider-padding
  vertical-align: $breadcrumbs-vertical-align
