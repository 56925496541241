@import ./index

.v-rating
  max-width: 100%
  display: inline-flex
  white-space: $rating-white-space

  &--readonly
    pointer-events: none

.v-rating__wrapper
  align-items: $rating-item-align-items
  display: inline-flex
  flex-direction: column

  &--bottom
    flex-direction: column-reverse

.v-rating__item
  display: inline-flex
  position: relative

  label
    cursor: pointer

  .v-btn--variant-plain
    opacity: $rating-item-button-opacity

  .v-btn
    transition-property: $rating-item-button-transition-property

    .v-icon
      transition: inherit
      transition-timing-function: $rating-item-transition-timing-function

  &:hover:not(.v-rating__item--focused)
    .v-icon
      transform: $rating-item-icon-transform

  &--focused
    .v-btn__overlay
      display: block
      opacity: $rating-item-focused-button-overlay-opacity

  &--half
    overflow: hidden
    position: absolute
    width: 50%
    z-index: 1

    .v-btn__overlay,
    &:hover .v-btn__overlay
      opacity: 0

.v-rating__hidden
  height: 0
  opacity: 0
  position: absolute
  width: 0
