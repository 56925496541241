@import './index'

.v-responsive
  display: flex
  flex: 1 0 auto
  max-width: 100%
  overflow: hidden
  position: relative

.v-responsive__content
  flex: 1 0 0px
  max-width: 100%

.v-responsive__sizer ~ .v-responsive__content
  margin-inline-start: -100%

.v-responsive__sizer
  flex: 1 0 0px
  transition: padding-bottom 0.2s $standard-easing
  pointer-events: none
