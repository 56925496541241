// Imports
@import ./index

.v-chip
  align-items: center
  cursor: default
  display: inline-flex
  position: relative
  text-decoration: none

  @at-root
    @include chip-sizes()
    @include chip-density('height', $chip-density)

  @include border($chip-border...)
  @include elevation($chip-elevation)
  @include rounded($chip-border-radius)
  @include variant($chip-variants...)

  &--border
    border-width: $chip-border-thin-width

  &--link
    cursor: pointer

  &--label
    @include rounded($chip-label-border-radius)

// Elements
.v-chip__append
  margin-inline-start: $chip-append-margin-inline-start
  margin-inline-end: $chip-append-margin-inline-end

  + .v-chip__close
    margin-inline-start: 0

.v-chip__prepend
  margin-inline-start: $chip-prepend-margin-inline-start
  margin-inline-end: $chip-prepend-margin-inline-end

.v-chip__close
  align-self: center
  cursor: pointer
  display: inline-flex
  flex: 0 1 auto
  font-size: $chip-close-size
  max-height: $chip-close-size
  max-width: $chip-close-size
  user-select: none
  margin-inline-start: $chip-close-margin-inline-start
  margin-inline-end: $chip-close-margin-inline-end

  .v-icon
    font-size: inherit

.v-chip--disabled
  opacity: $chip-disabled-opacity
  pointer-events: none
  user-select: none

.v-chip--label
  border-radius: $chip-label-border-radius
