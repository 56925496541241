// Defaults
$icon-btn-bottom-navigation-font-size: 24px !default;
$icon-btn-margin-start-multiplier: -9 !default;
$icon-btn-margin-end-multiplier: 4.5 !default;
$icon-btn-margin-start: calc(var(--v-btn-height) / #{$icon-btn-margin-start-multiplier}) !default;
$icon-btn-margin-end: calc(var(--v-btn-height) / #{$icon-btn-margin-end-multiplier}) !default;
$icon-left-margin-left: map-get($grid-gutters, 'md') !default;
$icon-letter-spacing: normal !default;
$icon-line-height: 1 !default;
$icon-margin-end: map-get($grid-gutters, 'md') !default;
$icon-margin-start: map-get($grid-gutters, 'md') !default;
$icon-size: 1em !default;
$icon-text-indent: 0 !default;
$icon-vertical-align: middle !default;

$icon-sizes: () !default;
$icon-sizes: map-deep-merge(
  (
    'x-small': 1em,
    'small': 1.25em,
    'default': 1.5em,
    'large': 1.75em,
    'x-large': 2em,
  ),
  $icon-sizes
);
