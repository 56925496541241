// Defaults
$sheet-background: rgb(var(--v-theme-surface)) !default;
$sheet-color: rgb(var(--v-theme-on-surface)) !default;
$sheet-border-color: $border-color-root !default;
$sheet-border-radius: 0 !default;
$sheet-border-style: $border-style-root !default;
$sheet-border-thin-width: thin !default;
$sheet-border-width: 0 !default;
$sheet-elevation: 0 !default;
$sheet-positions: absolute fixed !default;
$sheet-rounded-border-radius: $border-radius-root !default;

$sheet-border: (
  $sheet-border-color,
  $sheet-border-style,
  $sheet-border-width,
  $sheet-border-thin-width
) !default;

$sheet-theme: (
  $sheet-background,
  $sheet-color
) !default;

// VDialog
$sheet-dialog-elevation: 24 !default;
