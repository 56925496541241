@import './index'

// Block
.v-dialog
  z-index: $dialog-z-index

  .v-overlay__content
    max-height: $dialog-overlay-content-max-height
    min-width: $dialog-overlay-content-min-width
    max-width: $dialog-overlay-content-max-width

    @include rounded($dialog-overlay-content-border-radius)

    @media #{$dialog-overlay-content-mobile-breakpoint}
      max-width: $dialog-overlay-content-mobile-max-width

    > .v-card
      > .v-card-avatar
        padding: $dialog-card-avatar-padding

      > .v-card-header
        padding: $dialog-card-header-padding

        + .v-card-text
          padding-top: $dialog-card-header-text-padding-top

      > .v-card-text
        font-size: inherit
        letter-spacing: $dialog-card-text-letter-spacing
        line-height: inherit
        padding: $dialog-card-text-padding

.v-dialog--fullscreen
  --v-scrollbar-offset: 0px

  .v-overlay__content
    border-radius: 0
    margin: 0
    width: 100%
    height: 100%
    overflow-y: auto
    top: 0
    left: 0
