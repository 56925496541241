// Imports
@import './index'

.v-icon
  align-items: center
  display: inline-flex
  font-feature-settings: 'liga'
  height: $icon-size
  justify-content: center
  letter-spacing: $icon-letter-spacing
  line-height: $icon-line-height
  position: relative
  text-indent: $icon-text-indent
  user-select: none
  vertical-align: $icon-vertical-align
  width: $icon-size

  &--left
    margin-inline-end: $icon-margin-start

  &--right
    margin-inline-start: $icon-margin-end

  @each $name in $sizes
    &--size-#{$name}
      font-size: map-get($icon-sizes, $name)

.v-icon__svg
  fill: currentColor

.v-icon
  &--size-default
    .v-btn:not(.v-btn--icon):not(.v-btn--stacked) &
      .v-bottom-navigation &
        font-size: $icon-btn-bottom-navigation-font-size

      .v-bottom-navigation:not(.v-bottom-navigation--horizontal) &
        margin: 0

  &--left
    .v-btn:not(.v-btn--icon) &
      margin-inline-start: $icon-btn-margin-start
      margin-inline-end: $icon-btn-margin-end

  &--right
    .v-btn:not(.v-btn--icon) &
      margin-inline-start: $icon-btn-margin-end
      margin-inline-end: $icon-btn-margin-start
