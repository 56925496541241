// Imports
@import ./index

.v-badge
  display: inline-block
  line-height: $badge-line-height

.v-badge__badge
  align-items: center
  border-radius: $badge-border-radius
  display: flex
  font-size: $badge-font-size
  font-weight: $badge-font-weight
  height: $badge-height
  justify-content: center
  min-width: $badge-min-width
  padding: $badge-padding
  pointer-events: auto
  position: absolute
  text-align: center
  text-indent: 0
  transition: $badge-transition
  white-space: nowrap

  .v-badge--bordered &
    &::after
      border-radius: inherit
      border-style: $badge-border-style
      border-width: $badge-border-width
      bottom: 0
      color: $badge-border-color
      content: ''
      left: 0
      position: absolute
      right: 0
      top: 0
      transform: $badge-border-transform

  .v-badge--dot &
    border-radius: $badge-dot-border-radius
    height: $badge-dot-height
    min-width: 0
    padding: 0
    width: $badge-dot-width

    @include theme($badge-theme...)

    &::after
      border-width: $badge-dot-border-width

  .v-badge--inline &
    position: relative
    vertical-align: $badge-inline-vertical-align

  .v-icon
    color: inherit

  img,
  .v-img
    height: 100%
    width: 100%

.v-badge__content
  padding: $badge-content-padding

.v-badge__wrapper
  display: flex
  position: relative

  .v-badge--inline &
    align-items: center
    display: inline-flex
    justify-content: center
    margin: $badge-wrapper-margin
