// Defaults
$footer-background: rgb(var(--v-theme-surface)) !default;
$footer-border-radius: map-get($rounded, 0) !default;
$footer-color: rgb(var(--v-theme-on-surface)) !default;
$footer-flex: 1 1 auto !default;
$footer-font-size: 14px;
$footer-elevation: 0 !default;
$footer-padding: 8px 24px !default;
$footer-positions: absolute fixed !default;
$footer-rounded-border-radius: $border-radius-root !default;

$footer-theme: (
  $footer-background,
  $footer-color
) !default;
