@mixin states ($selector: '&::before', $active: true)
  &:hover
    #{$selector}
      opacity: calc(#{map-get($states, 'hover')} * var(--v-theme-overlay-multiplier))

  &:focus
    #{$selector}
      opacity: calc(#{map-get($states, 'focus')} * var(--v-theme-overlay-multiplier))

  @if ($active)
    &--active
      @include active-states($selector)

@mixin active-states ($selector: '::before')
  &:hover#{$selector},
  #{$selector}
    opacity: calc(#{map-get($states, 'activated')} * var(--v-theme-overlay-multiplier))

  &:focus
    #{$selector}
      opacity: calc(#{map-get($states, 'pressed')} * var(--v-theme-overlay-multiplier))
