@keyframes v-shake {
  59% {
    margin-left: 0;
  }

  60%, 80% {
    margin-left: 2px;
  }

  70%, 90% {
    margin-left: -2px;
  }
}
